
const VER = 1.0;

export default Object.freeze({
	APP_ID: 'bottles',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: '',
		isAdmin: false,
		data: {
      spreadsheetId: null,
      sheets: [], // {id: '', storeName: '', url: ''}
      refreshToken: null,
      theme: 'dark', // 'system', 'dark', 'light'
    },
	},
  STORE_STATE: {
    accessToken: null, // アクセストークン
    expireAt: 0, // トークンの有効期限
    isReady: false, // ログイン状態判定 Ready
    user: null, // ユーザー情報
    firstRowIndex: null, // 開始行番号
    bottles: null, // ボトル全データ
    keyword0: null, // 'num' or 'remain'
    keyword1: null, // 'brand'
    keyword2: null, // 'name' or 'memo'
    bottleIndex: null, // 詳細ボトル番号
    isSearchFocused: false, // 検索ボックスfocus判定
    isSearching: false, // 検索中判定
    editable: null, // sheet permission
    errorCode: null, // エラーコード
  },
	URLS: {
		site: 'https://apps.wanakijiji.com',
    pwa: '',
		play: '',
		app: '',
    privacy: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/761',
    request: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/759',
	},
	API_KEYS: {
		admob: {
			android: 'ca-app-pub-3940256099942544/6300978111', // デモID
			ios: 'ca-app-pub-3940256099942544/2934735716', // デモID
		},
    // index.html & capacitor.config.json
    // bottles (bottle.wanakijiji.com)
    client_id: '6361974247-ci3fdbhk8746hkalhe5q0jfbvtdva1t4.apps.googleusercontent.com',
    client_secret: 'LACcNgd71t2klth1yY66O6YZ',
    // bottles2 (bottles.aroundit.net)
    // client_id: '817515255869-t67dgba974n1pruk987buetm287nbco8.apps.googleusercontent.com',
    // client_secret: 'rbWlNUsHlctWWLI2m5gfH3e9',
  },
  FIREBASE: {
    apiKey: "AIzaSyC5LybZUI5nwe37qzCFaZwvdBELpF9WcV8",
    authDomain: "bottles-2021.firebaseapp.com",
    projectId: "bottles-2021",
    storageBucket: "bottles-2021.appspot.com",
    messagingSenderId: "6361974247",
    appId: "1:6361974247:web:68aee921b9e5af63fda942",
    measurementId: "G-8TN0TLCYL8"
  },
  SOURCE: {
    name: {ja: '', en: ''},
    url: {ja: '', en: ''},
  },
  // 表示メニュー一覧
  MENU: {
    order: ['home', 'account', 'request', 'contact', 'review', 'privacy'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [{
      id: 'account',
      icon: 'mdi-account-multiple',
      to: '/account',
      title: {ja: 'アカウントとデータ', en: 'Account'},
    }], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#BBDEFB',
      primaryBG: '#263238',
      secondary: '#FF6F00',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#37474F',
    },
    dark: {
      primary: '#BBDEFB',
      primaryBG: '#263238',
      secondary: '#FF6F00',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#37474F',
    },
  },
  DATA_STRUCTURE: {
    veridate: ['id', 'createdAt', 'updatedAt', 'brand', 'num', 'remain', 'names', 'memos'],
    current: ['id', 'createdAt', 'updatedAt', 'brand', 'num', 'remain', 'names', 'memos'],
    range: 'A1:J',
    rangeStore: 'J3:J3'
  },

})
